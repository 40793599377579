<template>
	<div class="doctor-list">

		<el-card class="filter-container" shadow="never">

			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="100px">
                    <el-row>
                        <el-form-item label="登录用户名：">
                            <el-input style="width: 300px" v-model="keyword" placeholder="用户名"></el-input>
                        </el-form-item>
                        <el-form-item label=" " style="float: right;">
                            <el-button type="primary" size="small" @click="getAdmin">
                                查询
                            </el-button>
                            <el-button type="info" size="small" @click="clearSearch">
                                重置
                            </el-button>
                            <el-button type="success" size="small" @click="updata('add')">
                                新增
                            </el-button>
                        </el-form-item>
                    </el-row>
				</el-form>
			</div>
		</el-card>

		<el-card style="margin: 20px 0;" shadow="never">
			<el-row style="display: flex;justify-content: flex-end;">
				<el-col><i class="el-icon-tickets"></i><span>管理员列表</span></el-col>
			</el-row>
		</el-card>


		<!-- 弹窗 -->
		<el-dialog :title="action=='add'?'添加管理员':'修改管理员'" :visible.sync="allocDialogVisible" width="60%" :modal-append-to-body="modalAppendToBody">
			<div style="height: 500px; overflow-y: auto;">
				<el-form ref="form" :model="form"  :rules="dataRule" label-width="100px">
					<el-form-item label="所属用户组" prop="groupId">
						<el-select v-model="form.groupId" placeholder="所属用户组" style="width: 50%;">
							<el-option v-for="item in groupData" :label="item.groupName" :key="item.groupId" :value="item.groupId"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="登录用户名" prop="username">
						<el-input v-model="form.username"  :disabled="action=='edit'" style="width: 50%;"></el-input>
					</el-form-item>
					<el-form-item label="登录密码" prop="pwd">
						<el-input v-model="form.pwd" style="width: 50%;"></el-input>
					</el-form-item>

					<el-form-item label="用户邮箱">
						<el-input v-model="form.email" style="width: 50%;"></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input v-model="form.tel" style="width: 50%;"></el-input>
					</el-form-item>
				<!-- 	<el-form-item label="通用网名">
						<el-input v-model="form.realName" style="width: 50%;"></el-input>
					</el-form-item> -->

					<el-form-item label="是否启用">
						<el-switch v-model="form.open"></el-switch>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
						<el-button type="info"   @click="allocDialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>


		</el-dialog>

		<el-card style="margin-top: 20px;" shadow="never">
			<el-table :data="tableData" style="width: 100%;">
				<el-table-column label="登录用户名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminUsername }}</span>
					</template>
				</el-table-column>
				<el-table-column label="邮箱" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminEmail }}</span>
					</template>
				</el-table-column>

				<el-table-column label="用户组" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.groupName }}</span>
					</template>
				</el-table-column>

				<!-- <el-table-column label="真实姓名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminRealname }}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="电话号码" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminTel }}</span>
					</template>
				</el-table-column>
				<el-table-column label="登陆次数" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminHits }}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="IP地址" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.adminIp }}</span>
					</template>
				</el-table-column> -->

				<el-table-column label="是否启用" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.adminOpen" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>


				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" @click="updata('edit',scope.row)" type="text">修改</el-button>
                            <el-button size="mini" @click="del(scope)" type="text">删除</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>


<!-- 
		<el-pagination :hide-on-single-page="hideonsinglepage" background :total="5" layout="prev, pager, next">
		</el-pagination> -->

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					groupId: '',
					email: '',
					open: "",
					pwd: "",
					realName: "",
					tel: "",
					username: "",
					
				},
				tableData: [],
				groupData:[],
				Switch: false,
				hideonsinglepage: false,
				allocDialogVisible: false,
				keyword: '',
				allocRoleIds: '',
				modalAppendToBody: false,
				action:"add",
                dataRule: {
                    groupId: [
                        { required: true, message: '所属用户组不能为空', trigger: 'change' }
                    ],
                    username: [
                        { required: true, message: '登录用户名不能为空', trigger: 'blur' }
                    ],
                    pwd: [
                        { required: true, message: '登录密码不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			// 提交
			onSubmit() {
				const that = this;
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        let apiUrl;
                        that.form.open = that.form.open==true?1:0;


                        if(that.action=="add"){
                            that.form.pwdSalt = that.form.pwd;
                            apiUrl = "/admin/manage/user_add";
                        }
                        else if(that.action="edit"){
                            apiUrl = "/admin/manage/edit";
                        }

                        that.api(apiUrl,that.form).then(res=>{
                            if(res.code==200){
                                that.$message.success("操作成功!");
                                that.getAdmin();
                                that.allocDialogVisible=false;
                            }else{
                                that.$message.error("操作失败!");
                            }
                        })
                    }
                })


			},
			// 删除方法
			del(delOjb){

				const that = this;
                this.$confirm('您确认要删除该条管理员信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/manage/user_delete',{adminId:delOjb.row.adminId}).then(res=>{
                        if(res.code==200){
                            that.$message.success("删除成功!");
                            that.tableData.splice(delOjb.$index,1);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

				
			},
			// 修改
			updata(ac,e) {
				const that = this;
				that.allocDialogVisible = true;
				this.action = ac;
				
				if(ac=='edit'){
					that.form.email=e.adminEmail;
					that.form.open=e.adminOpen;
					// that.form.realName=e.adminRealname;
					that.form.tel=e.adminTel;
					that.form.username=e.adminUsername;
					// that.form.pwd=e.adminPwd;
					that.form.groupId=e.groupId||'';
					that.form.adminId=e.adminId;
					
				}else if(ac='add'){
					that.form={}
				}
				
				
				// if(ac=='edit'){
				// 	let data = that.$utils.getOne(that.tableData,id,"adminId");
				// 	that.form.adminId = id;
				// 	that.form.email = data.adminEmail;
				// 	that.form.open = data.adminOpen;
				// 	// that.form.pwd = data.adminPwd;
				// 	that.form.realname = data.adminRealname;
				// 	that.form.tel = data.adminTel;
				// 	that.form.username = data.adminUsername;
				// 	that.form.groupId = data.memberId;
				// }
				// else if(ac=='add'){
				// 	that.form={}
				// }
			},
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/manage/user_state',{adminId:e.row.adminId}).then(res=>{
					if(res.code==200){
						that.getAdmin();
					}
				})
			},
			//获取分组信息
			getGroups(){
				this.api('/admin/manage/group_list').then(res=>{
					this.groupData = res.data;
				});
			},
			//清除查询
			clearSearch(){
				
				this.keyword="";
				this.getAdmin();
			},
			//获取管理员列表
			getAdmin(){
				
				const that = this;
				let query = {name:that.keyword};
				that.api('/admin/manage/user_list',query).then(res => {
					
					for(var i =0; i<res.adminList.length;i++){
						if(res.adminList[i].adminOpen=='1'){
							res.adminList[i].adminOpen=true
						}else{
							res.adminList[i].adminOpen=false
						}
					}					
					
					that.tableData = res.adminList;
					
				})
				
				
			}
		},
		created(){
			this.getAdmin();
			this.getGroups();
		}
	}
</script>

<style lang="less" scoped>
	.btn-add{
		float:right;
	}

</style>
